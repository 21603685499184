@charset "UTF-8";
// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

// Make sure the charset is set appropriately

// Import our own (partial) variables from the assets folder
 @import "variables";

// Normalize scss from bower components foundation
  @import "./versions/2.0.6/bower_components/foundation/scss/normalize.scss";

// Behold, here are all the Foundation components.
  @import "./versions/2.0.6/bower_components/foundation/scss/foundation.scss";
